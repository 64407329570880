<template>
  <div>
    <v-navigation-drawer
        app
        color="primary"
        dark
        permanent
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            CircleUp
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <v-list-item
            link
            :to="{name: 'dashboard'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            :to="{name: 'users'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            :to="{name: 'circles'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Circles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            :to="{name: 'payments'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-cash</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Payments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            :to="{name: 'sms'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-cellphone-message</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>SMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            :to="{name: 'push_notifications'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Push Notifications</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            link
            :to="{name: 'admins'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-cowboy-hat</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Admins</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
            @click.prevent="refresh"
        >
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Refresh data</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-spacer></v-spacer>
        <v-list-item
            @click.prevent="logout"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <transition
          name="fade"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
      >
        <router-view></router-view>
      </transition>
    </v-main>

    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Main",
  data: () => ({
    overlay: false,
    prevHeight: 0
  }),
  methods: {
    async refresh() {
      this.overlay = true
      await this.$store.dispatch('fetchData')
      this.overlay = false
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const {height} = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    logout() {
      localStorage.removeItem('token')
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
}
</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>